/** @jsxImportSource @emotion/react */
import { useState, useRef, useEffect } from "react";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import WaiverContent from "../components/WaiverContent";
import { useEnforceUserFlow } from "../hooks";

export default function Waiver() {
  const [disabled, setDisabled] = useState(true);
  const scrollRef = useRef(null);
  const bottomElmRef = useRef(null);
  const [value, setValue] = useState({});
  const enforceUserFlow = useEnforceUserFlow();

  useEffect(() => {
    enforceUserFlow();
  }, []);
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop <= 1;

    if (bottom) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const linkStyles = css`
    pointer-events: ${disabled ? "none" : "auto"};
    opacity: ${disabled ? "0.5" : "1"};
  `;

  useEffect(() => {
    if (!scrollRef.current) return;

    scrollRef.current.addEventListener("scroll", handleScroll);
    scrollRef.current.addEventListener("scrollstop", handleScroll);

    return () => {
      //remove scroll listener from ref
      try {
        scrollRef.current.removeEventListener("scroll", handleScroll);
        scrollRef.current.removeEventListener("scrollstop", handleScroll);
      } catch (e) {
        console.log(e);
      }
    };
  }, [scrollRef.current]);
  return (
    <div
      screen={"waiver"}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
      `}
    >
      <div
        ref={scrollRef}
        css={css`
          display: flex;
          flex-shrink: 1;
          flex-basis: 100%;
          overflow: hidden;
          overflow-y: scroll;
          padding: 24px;
          flex-direction: column;
          max-width: 624px;
        `}
      >
        <WaiverContent ref={bottomElmRef} />
        {/* <div
          ref={bottomElmRef}
          style={{ height: "100px", width: "100%", backgroundColor: "red" }}
        >
          a
        </div> */}
      </div>
      <div
        css={css`
          display: flex;
          background-color: #d9d9d9;
          justify-content: center;
          align-items: center;
          /* flex-basis: 80px; */
          width: 100%;
          box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.35);
          padding: 24px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 624px;
          `}
        >
          <p
            css={css`
              font-size: 14px;
              font-weight: normal;
              padding: 0;
              line-height: normal;
              margin-right: 14px;
            `}
          >
            Read and scroll to the bottom to continue
            {/* {JSON.stringify(value)} */}
          </p>
          <Link to="/how-many" css={linkStyles}>
            Continue
          </Link>
        </div>
      </div>
    </div>
  );
}
