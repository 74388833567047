/** @jsxImportSource @emotion/react */
import { forwardRef } from "react";
import { css } from "@emotion/react";

function WaiverParagraph({ text }) {
  return (
    <p
      css={css`
        font-size: 14px;
        font-family: "input-serif-condensed", serif;
        font-weight: 400;
        font-style: normal;
      `}
    >
      {text}
    </p>
  );
}

export default forwardRef(WaiverContent);

function WaiverContent(props, ref) {
  return (
    <div
      ref={ref}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      `}
    >
      <div>
        <WaiverParagraph
          text="
          IN CONSIDERATION of being permitted to participate at the residence of
          Jack Biltis in kart racing, basketball, sports simulation, arcade and
          related events and activities (“collectively the “Activities”)
          provided and facilitated by Jack Biltis (“Facilitator”), I HEREBY
          AGREE for myself, my heirs and legal representatives: I fully
          understand the risks and completely assume all risks, known and
          unknown, of participating in Activities, including the risk of death
          or serious bodily injury. I represent that I am in good health and
          physically able and ready to participate in the Activities, and do not
          have any physical illness, injury or disability that would make my
          participation in these Activities unwise or unnecessarily risky or
          dangerous to myself or others. I accept and assume all such inherent
          dangers and risks of bodily injury, permanent disability, death,
          and/or property damage, even if caused, in whole or in part, by the
          negligence of the Facilitator, and all of their respective members,
          managers, officers, directors, shareholders, affiliated organizations
          and entities, representatives and sponsors, and all volunteers,
          contractors, agents, members, and employees (collectively the
          “Facilitator Parties”)."
        ></WaiverParagraph>
        <WaiverParagraph
          text="
          Recognizing the possibility of death and serious bodily injury
          associated with participating in the Activities, I hereby agree to
          waive, release, hold harmless and/or discharge the Facilitator Parties
          from any and all claims, demands, damages, losses, injuries,
          liabilities, obligations, costs or expenses of any kind which are made
          or could be made by me, or on my behalf, or by my heirs or legal
          representatives as a result of my participation in the Activities,
          even though such claims may arise due to the negligence, carelessness
          or recklessness of such Facilitator Parties."
        ></WaiverParagraph>
        <WaiverParagraph
          text="
          I agree and understand that if I elect to consume alcohol prior to or
          during my participation in the Activities, I agree to assume all
          risks, known and unknown, related to consuming such alcohol and agree
          to be fully responsible and liable for any and all claims, demands,
          damages, losses, injuries, liabilities, obligations, costs or expenses
          of any kind which may arise due to my consumption of such alcohol
          (collectively the “Claims”). I hereby agree to indemnify, defend, and
          hold harmless the Facilitator Parties from any such Claims. This
          release and waiver of claims is binding on me, my heirs, executors,
          administrators, legal representatives, assigns and successors in
          interest. I understand that I am giving up my right to sue the
          Facilitator Parties and giving up other valuable and substantial
          rights in exchange for being permitted to participate in the
          Activities."
        ></WaiverParagraph>
        <WaiverParagraph
          text="
          I agree and understand that following all instructions and rules at
          all times while participating in the Activities is a requirement for
          participation. I agree that if I fail to follow such instructions and
          rules and act responsibly, I waive any and all right to continue
          participating in the Activities and any benefits associated with being
          a participant. I agree to PAY for any willful or grossly negligent
          acts that result in damage to the Facilities caused by participant;
          and I hereby grant to the Facilitator the right to photograph,
          videotape or otherwise capture my image, appearance or likeness and to
          use such images for any legitimate purposes, commercial or otherwise.
          By signing this Release, or digitally acknowledging acceptance of its
          terms, I agree that I have read the terms, agree to be bound by the
          terms and do so voluntarily and knowingly. If I am signing on behalf
          of my minor child, I hereby execute this Release on behalf of my minor
          child with a full and complete understanding that I am executing a
          release and waiver of potential claims on behalf of my minor child and
          myself. I agree that no pictures or video will be shared on any social
          media platform including, but not limited to, Facebook, X (Twitter),
          SnapChat, Discord, Instagram, etc."
        ></WaiverParagraph>
        <WaiverParagraph
          text="
          I HAVE READ THIS AGREEMENT. I CERTIFY THAT I AM OF LAWFUL AGE AND
          LEGALLY COMPETENT TO SIGN THIS AGREEMENT; THAT I UNDERSTAND THAT THE
          TERMS HEREIN ARE CONTRACTUAL AND NOT A MERE RECITAL; THAT I HAVE
          SIGNED THIS AGREEMENT AS MY OWN FREE ACT AND IF I HAVE ANY DOUBTS
          CONCERNING THE CONTENTS OF THIS AGREEMENT, I WILL CONSULT AN ATTORNEY
          BEFORE SIGNING IT."
        ></WaiverParagraph>
      </div>
    </div>
  );
}
