/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 24px;
      `}
    >
      <div
        css={css`
          justify-content: center;
          align-items: center;
          flex-direction: column;
          display: flex;
          flex-basis: 100%;
        `}
      >
        <p
          css={css`
            font-family: "jeanne-moderno-geometrique", sans-serif;
            font-weight: 600;
            font-style: normal;
            font-size: 80px;
            color: #404040;
          `}
        >
          b
        </p>
        <p
          css={css`
            font-size: 24px;
          `}
        >
          Biltis Waiver
        </p>
        <div
          css={css`
            width: 85%;
          `}
        >
          <p>
            Before the fun begins, we need you to fill out a quick waiver. It's
            just a simple step to make sure everyone's on the same page about
            the risks and rules. This way, we can all have a great time without
            any hiccups. It won't take long and once you're done, you'll be all
            set to go.
          </p>

          <p>Thanks for helping us keep things smooth and safe.</p>
        </div>
      </div>

      <div
        css={css`
          justify-content: flex-end;
          align-items: center;
          flex-direction: column;
          display: flex;
          flex-basis: 20%;
        `}
      >
        <Link to="intro">Continue</Link>
      </div>
    </div>
  );
}
