/** @jsxImportSource @emotion/react */
import { useState, useRef, useEffect, useMemo } from "react";
import { css } from "@emotion/react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useWaiverContext } from "../state";
import Input from "../components/Input";
import { useEnforceUserFlow } from "../hooks";

function isValid(name, date, checked) {
  return name.length > 5 && date.length > 0 && checked;
}

export default function Sign() {
  const navigate = useNavigate();
  const { current } = useParams();
  const {
    numberOfChildren,
    legalName,
    date,
    setLegalName,
    setDate,
    getDependent,
    setDependent,
    dependents,
  } = useWaiverContext();
  const enforceUserFlow = useEnforceUserFlow();

  useEffect(() => {
    enforceUserFlow();
  }, []);

  const [checked, setChecked] = useState(false);
  const headerText = useMemo(() => {
    if (Number(current) === Number(numberOfChildren)) {
      return "";
    } else {
      return `Signing: ${Number(current) + 1} of ${
        Number(numberOfChildren) + 1
      }`;
    }
  }, [current, numberOfChildren]);

  const href = useMemo(() => {
    if (Number(current) === Number(numberOfChildren)) {
      return "/complete";
    } else {
      return `/sign/${Number(current) + 1}`;
    }
  }, [current, numberOfChildren]);

  const linkText = useMemo(() => {
    if (Number(current) === Number(numberOfChildren)) {
      return "Complete";
    } else {
      return "Next";
    }
  }, [current, numberOfChildren]);

  const dependent = useMemo(() => {
    if (current === 0) return null;

    return getDependent(current);
  }, [current, getDependent, dependents]);

  const description = useMemo(() => {
    if (Number(numberOfChildren) === 0) {
      return "Great, let's get you squared away.";
    }

    if (Number(current) === 0 && Number(numberOfChildren) > 0) {
      return "First, let’s get your agreement taken care of.";
    } else {
      return `Now, let’s get the agreement for child #${current} taken care of.`;
    }
  }, [current, numberOfChildren]);

  const name = dependent ? dependent.legalName : legalName;
  const inputDate = dependent ? dependent.date : date;

  const linkStyles = useMemo(() => {
    const disabled = !isValid(name, inputDate, checked);
    return css`
      pointer-events: ${disabled ? "none" : "auto"};
      opacity: ${disabled ? "0.5" : "1"};
    `;
  }, [checked, name, inputDate, numberOfChildren.length]);

  const labelText = useMemo(() => {
    if (Number(current) === 0) {
      return "I agree to the terms of this waiver for myself.";
    } else {
      return `I agree to the terms of this waiver this child.`;
    }
  }, [current, numberOfChildren]);
  useEffect(() => {
    if (numberOfChildren.length === 0) {
      navigate("/");
      return;
    }

    setChecked(false);
  }, [current]);
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 24px;
        padding-top: 48px;
        width: 100%;
      `}
    >
      <div
        css={css`
          width: 85%;
        `}
      >
        <p
          css={css`
            text-align: center;
            opacity: 0.5;
            padding-bottom: 24px;
          `}
        >
          {headerText}
        </p>

        <p>{description}</p>
        <div
          css={css`
            margin-bottom: 24px;
          `}
        >
          <Input
            type="text"
            value={!dependent ? legalName : dependent?.legalName || ""}
            onChange={(e) => {
              if (!dependent) {
                setLegalName(e.target.value);
              } else {
                setDependent(current, {
                  ...dependent,
                  legalName: e.target.value,
                });
              }
            }}
            label={
              !dependent
                ? "Legal Name (First and Last Name)"
                : `Child #${current} Legal Name (First and Last Name)`
            }
          />
        </div>

        <div>
          <Input
            label="Today's Date"
            type="date"
            value={!dependent ? date : dependent?.date || ""}
            onChange={(e) => {
              if (!dependent) {
                setDate(e.target.value);
              } else {
                setDependent(current, { ...dependent, date: e.target.value });
              }
            }}
          />
        </div>

        <div
          css={css`
            display: flex;
            align-items: center;
            margin-top: 24px;
          `}
        >
          <input
            id="checkbox"
            type="checkbox"
            css={css``}
            checked={checked}
            onChange={(e) => {
              setChecked(e.target.checked);
            }}
          />
          <label htmlFor="checkbox">{labelText}</label>
        </div>
      </div>
      <Link to={href} css={linkStyles}>
        {linkText}
      </Link>
    </div>
  );
}
