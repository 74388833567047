import { createContext, useState, useContext } from "react";

const initalState = {
  numberOfChildren: "",
  setNumberOfChildren: () => {},

  legalName: "",
  date: today(),
  dependents: [],
};

function today() {
  // Get year, month, and day part from the date
  const date = new Date();
  const year = date.toLocaleString("default", { year: "numeric" });
  const month = date.toLocaleString("default", { month: "2-digit" });
  const day = date.toLocaleString("default", { day: "2-digit" });
  return `${year}-${month}-${day}`;
}

export const WaiverContext = createContext(initalState);
export const useWaiverContext = () => useContext(WaiverContext);

export default function WaiverProvider({ children }) {
  const [numberOfChildren, _setNumberOfChildren] = useState("");
  const [legalName, setLegalName] = useState("");
  const [date, setDate] = useState(today);
  const [dependents, setDependents] = useState([]);

  function getDependent(param) {
    return dependents[param - 1];
  }

  function setDependent(param, value) {
    let newDependents = [...dependents];
    newDependents[param - 1] = value;

    setDependents(newDependents);
  }

  function setNumberOfChildren(value) {
    if (value < 0) return;
    _setNumberOfChildren(value);

    const defaultDependentData = new Array(Number(value))
      .fill(0)
      .reduce((arr, current) => {
        arr.push({ legalName: "", date: today() });
        return arr;
      }, []);

    setDependents(defaultDependentData);
  }

  function clearData() {
    setNumberOfChildren(initalState.numberOfChildren);
    setLegalName(initalState.legalName);
    setDate(initalState.date);
    setDependents(initalState.dependents);
  }

  function isDataSubmittable() {
    return (
      legalName &&
      date &&
      dependents.every((dependent) => dependent.legalName && dependent.date)
    );
  }

  return (
    <WaiverContext.Provider
      value={{
        numberOfChildren,
        setNumberOfChildren,
        legalName,
        setLegalName,
        date,
        setDate,
        getDependent,
        setDependent,
        dependents,
        clearData,
        isDataSubmittable,
      }}
    >
      {children}
    </WaiverContext.Provider>
  );
}
