/** @jsxImportSource @emotion/react */
import { useState, useRef, useEffect } from "react";
import { css } from "@emotion/react";
import { Link, redirect } from "react-router-dom";
import { useWaiverContext } from "../state";

export default function Input({
  type,
  label,
  value,
  onChange,
  placeholder,
  name,
  checked,
  onKeyDown,
  cssOverrides,
  helperText,
  id,
  ...props
}) {
  return (
    <>
      {label.length > 0 && <label>{label}</label>}
      <input
        data-form-type="other"
        data-lpignore="true"
        autoComplete="off"
        id={id}
        name={name}
        type={type}
        checked={checked}
        css={css`
          ${cssOverrides}
        `}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
      />
      {helperText.length > 0 && (
        <p
          css={css`
            font-size: 14px;
            font-weight: normal;
          `}
        >
          {helperText}
        </p>
      )}
    </>
  );
}

Input.defaultProps = {
  type: "text",
  label: "",
  value: "",
  onChange: () => {},
  placeholder: "",
  onKeyDown: (e) => {
    if (e.key === "Enter") {
      e.target.blur();
      //   e.preventDefault();
    }
  },
  cssOverrides: null,
  helperText: "",
};
