/** @jsxImportSource @emotion/react */
import { useState, useRef, useEffect } from "react";
import { css } from "@emotion/react";
import { Link, redirect } from "react-router-dom";
import { useWaiverContext } from "../state";
import Input from "../components/Input";
import { useEnforceUserFlow } from "../hooks";
export default function HowMany() {
  const { numberOfChildren, setNumberOfChildren } = useWaiverContext();
  const enforceUserFlow = useEnforceUserFlow();

  useEffect(() => {
    enforceUserFlow();
  }, []);

  const linkStyles = css`
    pointer-events: ${!numberOfChildren.length ? "none" : "auto"};
    opacity: ${!numberOfChildren.length ? "0.5" : "1"};
  `;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 24px;
        padding-top: 48px;
        width: 100%;
      `}
    >
      <div
        css={css`
          width: 85%;
        `}
      >
        <p>
          Before signing the waiver for yourself, how many children under the
          age of 18 are you signing for?
        </p>
        {console.log({ numberOfChildren })}
        {
          /*
            Loop through 5 times and render 5 inputs
          */

          [...Array(6)].map((_, i) => {
            const text =
              i === 0 ? "No Children" : i === 1 ? "1 Child" : `${i} Children`;
            return (
              <div
                key={i}
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <Input
                  type="radio"
                  name="children"
                  checked={numberOfChildren === String(i)}
                  id={`child-${i}`}
                  value={numberOfChildren}
                  onChange={(e) => setNumberOfChildren(String(i))}
                />
                <label htmlFor={`child-${i}`}>{text}</label>
              </div>
            );
          })
        }
      </div>
      <Link to={`/sign/0`} css={linkStyles}>
        Continue
      </Link>
    </div>
  );
}
