/** @jsxImportSource @emotion/react */
import { useEffect, useState, useRef } from "react";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { useWaiverContext } from "../state";
import { useEnforceUserFlow } from "../hooks";
import axios from "axios";
const keyframes = css`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @keyframes rotateStop {
    to {
      transform: rotate(360deg);
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      font-size: 60px;
      /* transform: translate(-51%, -24%); */
    }

    to {
      opacity: 1;
      font-size: 80px;
      transform: translate(-51%, -110%);
    }
  }

  @keyframes stuffFadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

function Processing({ processing }) {
  const [processingText, setProcessingText] = useState(
    processing ? "Processing..." : ""
  );
  const [showNewWaiver, setShowNewWaiver] = useState(false);
  const animationRef = useRef(null);
  const bRef = useRef(null);

  function clearAnimation() {
    if (!processing) {
      animationRef.current.style.animation = "rotateStop 1s ease-out forwards";
      bRef.current.style.animation = "fadeIn 1s 1s ease-in forwards";
    }
  }

  function bRefAnimationComplete() {
    setProcessingText("Waiver Complete!");
    setShowNewWaiver(true);
  }

  useEffect(() => {
    if (!animationRef.current || !bRef.current) return;
    if (processing) {
      setProcessingText("Processing...");
    }
    animationRef.current.addEventListener("animationiteration", clearAnimation);
    bRef.current.addEventListener("animationend", bRefAnimationComplete);
    return () => {
      try {
        animationRef.current.removeEventListener(
          "animationiteration",
          clearAnimation
        );

        bRef.current.addEventListener("animationend", bRefAnimationComplete);
      } catch (e) {}
    };
  }, [animationRef.current, bRef.current]);

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          justify-content: center;
          align-items: center;
          display: flex;
          flex-direction: column;
          position: relative;
          flex-basis: 50%;
        `}
      >
        <p
          ref={animationRef}
          css={css`
            font-family: "jeanne-moderno-geometrique", sans-serif;
            font-weight: 600;
            font-style: normal;
            font-size: 80px;
            color: #404040;
            transform-origin: 26px 14px;
            animation: rotate 1s linear infinite;
            padding: 0;
            position: relative;
            z-index: 3;
            ${keyframes}
          `}
        >
          o
        </p>

        <p
          ref={bRef}
          css={css`
            font-family: "jeanne-moderno-geometrique", sans-serif;
            font-weight: 600;
            font-style: normal;
            font-size: 80px;
            color: #404040;
            /* transform-origin: 26px 14px; */
            top: 50%;
            left: 50%;
            /* transform: translate(-52%, -110%); */
            transform: translate(-51%, -24%);
            font-size: 60px;
            position: absolute;
            opacity: 0;
            animation-fill-mode: forwards;
            ${keyframes}
            z-index: 1;
          `}
        >
          b
        </p>

        <p
          css={css`
            padding-top: 24px;
            position: relative;
            z-index: 10;
          `}
        >
          {processingText}
        </p>
      </div>
      {showNewWaiver && (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            flex-basis: 50%;
            opacity: 0;
            ${keyframes}
            animation: stuffFadeIn 1s .5s ease-in forwards;
          `}
        >
          <p
            css={css`
              padding-left: 24px;
              padding-right: 24px;
            `}
          >
            You will need to complete another waiver for an additional adult in
            your group.
          </p>
          <Link to="/">Close</Link>
        </div>
      )}
    </div>
  );
}

function Error() {
  return (
    <>
      <div
        css={css`
          justify-content: center;
          align-items: center;
          flex-direction: column;
          display: flex;
          flex-basis: 50%;
        `}
      >
        <p
          css={css`
            padding: 24px;
          `}
        >
          There is an error with the data, let's try this again.
        </p>
        <Link to="/">Start Over</Link>
      </div>
    </>
  );
}

export default function Complete() {
  const [processing, setProcessing] = useState(true);
  const [complete, setComplete] = useState(false);
  const [failed, setFailed] = useState(false);
  const { clearData, isDataSubmittable, legalName, date, dependents } =
    useWaiverContext();
  const enforceUserFlow = useEnforceUserFlow();

  useEffect(() => {
    enforceUserFlow();
  }, []);

  useEffect(() => {
    if (!processing || !isDataSubmittable()) return;

    axios
      .post("/create", { legalName, date, dependents })
      .then(() => {
        setProcessing(false);
        setComplete(true);
        clearData();
      })
      .catch((e) => {
        setComplete(false);
        setFailed(true);
      });
  }, [setProcessing, processing]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        height: 100%;
        padding: 24px;
        width: 100%;
      `}
    >
      {(isDataSubmittable() || complete) && (
        <Processing processing={processing} />
      )}
      {((!isDataSubmittable() && !complete) || failed) && <Error />}
    </div>
  );
}
