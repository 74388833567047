import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

export function useEnforceUserFlow() {
  const location = useLocation();
  const navigate = useNavigate();
  return () => {
    if (location.key === "default") {
      navigate("/");
    }
  };
}
