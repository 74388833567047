/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { useEnforceUserFlow } from "../hooks";
export default function Intro() {
  const enforceUserFlow = useEnforceUserFlow();

  useEffect(() => {
    enforceUserFlow();
  }, []);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 24px;
        padding-top: 48px;
        width: 100%;
      `}
    >
      <div
        css={css`
          width: 85%;
        `}
      >
        <h3
          css={css`
            text-align: left;
            padding: 24px 0;
          `}
        >
          Waiver Explaination:
        </h3>
        <ol
          css={css`
            list-style-position: inside;
            & li {
              margin-bottom: 12px;
            }
          `}
        >
          <li>
            <span>For Adults:</span> Each adult participant must complete an
            individual waiver.
          </li>
          <li>
            <span>For Minors:</span> Minors are not permitted to complete
            waivers independently. Instead, a parent or legal guardian must
            include the minor on their own waiver.
          </li>
        </ol>
      </div>

      <Link to="/waiver">Read Waiver</Link>
    </div>
  );
}
