import { createRoot } from "react-dom/client";
import Home from "./Home";
import Intro from "./pages/Intro";
import Waiver from "./pages/Waiver";
import HowMany from "./pages/HowMany";
import Sign from "./pages/Sign";
import Complete from "./pages/Complete";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Routes,
  createRoutesFromElements,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import WaiverProvider from "./state";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/intro",
    element: <Intro />,
  },
  {
    path: "/waiver",
    element: <Waiver />,
  },
  {
    path: "/how-many",
    element: <HowMany />,
  },
  {
    path: "/sign/:current",
    element: <Sign />,
  },
  {
    path: "/complete",
    element: <Complete />,
  },
]);

function App() {
  return (
    <WaiverProvider>
      <RouterProvider router={router}></RouterProvider>
    </WaiverProvider>
  );
}

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<App />);
